body {
  margin: 0 auto;
  font-family: 'Zulu Akkurat','Zulu Basis Grotesque Pro' ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Zulu Akkurat','Zulu Basis Grotesque Pro',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Zulu Akkurat';
    src: url('./assets/fonts/Akkurat/Akkurat.eot');
    src: url('./assets/fonts/Akkurat/Akkurat.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Akkurat/Akkurat.woff2') format('woff2'),
        url('./assets/fonts/Akkurat/Akkurat.woff') format('woff'),
        url('./assets/fonts/Akkurat/Akkurat.ttf') format('truetype'),
        url('./assets/fonts/Akkurat/Akkurat.svg#Akkurat') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
  font-family: 'Zulu Basis Grotesque Pro';
  src: url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Light.eot');
  src: url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Light.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Light.woff2') format('woff2'),
      url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Light.woff') format('woff'),
      url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Light.ttf') format('truetype'),
      url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Light.svg#BasisGrotesquePro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Zulu Basis Grotesque Pro';
  src: url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Regular.eot');
  src: url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Regular.woff2') format('woff2'),
      url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Regular.woff') format('woff'),
      url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Regular.ttf') format('truetype'),
      url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Regular.svg#BasisGrotesquePro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Zulu Basis Grotesque Pro';
  src: url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Medium.eot');
  src: url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Medium.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Medium.woff2') format('woff2'),
      url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Medium.woff') format('woff'),
      url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Medium.ttf') format('truetype'),
      url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Medium.svg#BasisGrotesquePro-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Zulu Basis Grotesque Pro';
  src: url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Bold.eot');
  src: url('assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Bold.woff2') format('woff2'),
      url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Bold.woff') format('woff'),
      url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Bold.ttf') format('truetype'),
      url('./assets/fonts/BasisGrotesquePro/BasisGrotesquePro-Bold.svg#BasisGrotesquePro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
